<template>
  <div class="content_box">
    <div id="result_wrapper">
      <div class="inner_max">
        <div class="result_content" v-show="waiting" v-loading="waiting">
          <p>{{ $t('thirdPartyDeposit.failed.countDownMsg', { countDown: countDown }) }}</p>
        </div>
        <div class="result_content" v-if="failure">
          <img src="@/assets/images/dialog_false.png" alt />
          <p>{{ $t('thirdPartyDeposit.result.failureMsg') }}</p>
          <router-link :to="{ name: 'home' }" class="el-button purple_button">
            {{ $t('common.button.bkToHm') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiThirdPartyDepositResult } from '@/resource';

export default {
  name: 'thirdPartyDepositFailed',
  data() {
    return {
      waiting: true,
      failure: false,
      countDown: 30
    };
  },
  methods: {
    getPaymentType() {
      return window.location.pathname;
    },
    waitingCountDown() {
      setInterval(() => {
        this.countDown--;
      }, 1000);
    },
    postApiThirdPartyDepositResult() {
      let paymentType = this.getPaymentType().split('/')[1];
      if (paymentType === 'thirdPartyDepositFailed') paymentType = this.getPaymentType().split('/')[2];
      console.log(paymentType);

      apiThirdPartyDepositResult(paymentType, this.$route.query).finally(result => {
        this.failure = true;
        this.waiting = false;
      });
    }
  },
  mounted() {
    this.waiting = true;
    this.waitingCountDown();

    this.postApiThirdPartyDepositResult();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/thirdPartyDepositResult.scss';
</style>
